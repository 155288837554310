import React, { useRef, useEffect, Fragment } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Head from 'next/head'
import { post } from '@/utils/api'
import { useMounted } from '@/utils/useMounted'
import { Modal, ModalContent } from '@/components/elements/Modal'
import { User } from '@/types/User'
import {
  useIdleState,
  useSetIdleState,
} from '@/components/contexts/IdleStateProvider'

type GUID = string | number

type UserPingParams = {
  request?: {
    url: string
    hash: string
  }
  navigator?: {
    platform?: string
    cookieEnabled?: boolean
    doNotTrack?: string
    languages?: string
    userAgent?: string
  }
  user_id?: string | number
  guid?: string | number
  activity?: {
    remaining?: number
    lastActiveTime?: number
    elapsed?: number
  }
}

// Credit: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

// Custom hook around setInterval
function useInterval(callback, delay: number) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const savedCallback = useRef(() => {})

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

interface UserPingProps {
  initialUser?: User
  pingInterval: number
  gracePeriod: number
  timeout: number
  guid: GUID
}

export const globalData = (): UserPingParams => {
  let data = {}
  if (window && window.location) {
    data = {
      ...data,
      request: {
        url: window.location.href,
        hash: window.location.hash,
      },
    }
  }
  if (navigator) {
    data = {
      ...data,
      navigator: {
        platform: navigator.platform,
        cookieEnabled: navigator.cookieEnabled,
        doNotTrack: navigator.doNotTrack,
        languages: navigator.languages,
        userAgent: navigator.userAgent,
      },
    }
  }
  return data
}

/**
 * Test expected vs actual users on id, role and role === 'admin'
 *
 * NB: No need to check status is active here, as ping would return 403 for inactive user
 */
const isExpectedUser = (expected: User, actual?: User): boolean =>
  expected.id === actual?.id && expected.role === actual?.role

/**
 * The guts of UserPing but only rendered if we are mounted
 */
const UserPingComponent = (props: UserPingProps) => {
  const { initialUser } = props
  // Always will be a user
  const user = initialUser // @TODO could useSWR for shared state/mutations useSelector(userSelector) || initialUser
  const setIsIdle = useSetIdleState()
  const isIdle = useIdleState()
  const { gracePeriod, pingInterval, guid, timeout } = props
  const user_id = user?.id || -9999

  const handleOnActive = () => {
    setIsIdle(false)
  }

  const handleOnIdle = () => {
    setIsIdle(true)
  }

  const currentIdleTimer = useIdleTimer({
    timeout: timeout,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
  })

  const getData = (): UserPingParams => {
    return {
      user_id,
      guid,
      activity: {
        remaining: currentIdleTimer.getRemainingTime(),
        lastActiveTime: currentIdleTimer.getLastActiveTime(),
        elapsed: currentIdleTimer.getElapsedTime(),
      },
      ...globalData(),
    }
  }

  const doLogout = () => {
    if (process.env.NODE_ENV === 'development') {
      console.trace('UserPing: doLogout')
    }
    window.location.href = `/autologout?url=${window.location.pathname}`
  }

  const ping = () => {
    if (!currentIdleTimer.isIdle()) {
      const data = getData()
      const url = `/api/user/${user_id}`
      post<UserPingParams, User>({ url }, data)
        .then((response) => {
          if (isExpectedUser(response.data, user)) {
            //   return dispatch(UserActions.changeUser({ user: response.data }))
            return
          }
          // 422 invalid is the only error possible from post() here and we do not expect it
          // other errors will be caught by catch below
          console.warn(
            'UserPing: Unexpected error response logging out: ',
            response
          )
          return doLogout()
        })
        .catch((error) => {
          // NB: ErrorBoundary cannot pick this one up so handle manually
          //     Code a bit repeated in ErrorBoundary
          console.warn('UserPing: Error: ', error)
          return doLogout()
        })
    }
  }

  useInterval(ping, pingInterval)
  /**
   * useEffect call with no dependencies means "run on first render"
   *
   * NB: Explictly ignoring the exhaustive-deps call as adding them
   *     causes this to re-render and run infinitely.
   *
   * @TODO this could be telling us we have the state management incorrect
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(ping, [])
  useEffect(() => {
    if (isIdle) {
      const timer = setTimeout(() => {
        console.warn('Grace period finished logging out')
        doLogout()
      }, gracePeriod)
      return () => clearTimeout(timer)
    }
  }, [isIdle, gracePeriod])

  if (!user_id) {
    // if we do not have a user we should exit early without failure
    console.warn('UserPing called without valid user property')
    return null
  }

  return (
    <Fragment>
      {isIdle && (
        <Fragment>
          <Head>
            <title>MyHR: Are you still there?</title>
          </Head>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            // className={`${classes.modal}`}
            open={true}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={true}>
              <Paper>
                <ModalContent className={`modal-autologout-warning`}>
                  <h2>Are you still there?</h2>
                  <p>You are about to be logged out due to inactivity.</p>
                  <p>
                    Do something (e.g. click or tap the page) to let us know
                    you&apos;re still there.
                  </p>
                </ModalContent>
              </Paper>
            </Fade>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  )
}

/**
 * UserPing component to keep session alive for the user if they are active
 * OR to auto log them out if they are not.
 *
 * Only does anything if we are mounted (i.e. client side only)
 *
 * - Initial ping on page load
 * - User ping if not idle every minute
 * - Logout if user ping unauthorised
 * - Logout if user ping response does not match current user (id, role and role === 'admin')
 * - If user is idle, warn them they will be logged out soon and after a grace period log them out
 */
export const UserPing = (props: UserPingProps): JSX.Element | null => {
  const mounted = useMounted()
  if (!mounted) {
    return null
  }
  return <UserPingComponent {...props} />
}
const defaultProps: UserPingProps = {
  pingInterval: 1 * 1000 * 60,
  gracePeriod: 1 * 1000 * 60 * 5,
  timeout: parseInt(process.env.PING_TIMEOUT || '' + 1 * 1000 * 60 * 40),
  // Possibly better ways to do this and maybe we should set a cookie for more persistence??
  guid: Date.now(),
}
UserPing.defaultProps = defaultProps
