import tokens from '@/tokens/tokens.json'

/**
 * Shape if type = 'dropShadow'
 */
type DropShadow = {
  color: string
  type: string
  x: string
  y: string
  blur: string
  spread: string
}

/**
 * Shape if type = 'boxShadow'
 */
type BoxShadow = DropShadow | Array<DropShadow>

/**
 * Shape if type = 'typography'
 */
type Typography = {
  fontFamily: string
  fontWeight: string
  lineHeight: string
  fontSize: string
  letterSpacing: string
  paragraphSpacing: string
  paragraphIndent: string
  textCase: string
  textDecoration: string
}

/**
 * A Typescript type to represent the `type` of a token value from tokens.json
 */
type TokenType =
  | 'spacing'
  | 'color'
  | 'fontFamilies'
  | 'fontWeights'
  | 'lineHeights'
  | 'fontSize'
  | 'letterSpacing'
  | 'paragraphSpacing'
  | 'paragraphIndent'
  | 'textCase'
  | 'textDecoration'
  | 'dropShadow'
  | 'boxShadow'
  | 'typography'
  | 'borderRadius'

type TokenValue = string | DropShadow | BoxShadow | Typography

/**
 *
 * flatten tokens to a single level object `{key: value}` so that the keys match figma
 * and the values are resolved
 *
 * <example>
 *  {
 *   global: {
 *     spacing: {
 *       '4000': {
 *         value: '320',
 *         type: 'spacing',
 *       },
 *       '0': {
 *         value: '0',
 *         type: 'spacing',
 *       },
 *       '50': {
 *         value: '4',
 *         type: 'spacing',
 *       },
 *     },
 *     neutral: {
 *       '25': {
 *         value: '#FFFFFF',
 *         type: 'color',
 *       },
 *       '100': {
 *         value: '#F9FAFB',
 *         type: 'color',
 *       },
 *     },
 *   },
 *   spacing: {
 *     spacing: {
 *       width: {
 *         xxs: {
 *           value: '{spacing.4000}',
 *           type: 'spacing',
 *         },
 *       },
 *       none: {
 *         value: '{spacing.0}',
 *         type: 'spacing',
 *       },
 *       '2xs': {
 *         value: '{spacing.50}',
 *         type: 'spacing',
 *       },
 *     },
 *   },
 *   colour: {
 *     bg: {
 *       primary: {
 *         value: '{neutral.25}',
 *         type: 'color',
 *       },
 *       secondary: {
 *         value: '{neutral.100}',
 *         type: 'color',
 *       },
 *     },
 *   },
 * }
 * will return
 * {
 *   "spacing/4000": "--var(--spacing-4000, 320px)",
 *   "spacing/0": "--var(--spacing-0, 0px)",
 *   "spacing/50": "--var(--spacing-50, 4px)",
 *   "neutral/25": "--var(--neutral-25, #FFFFFF)",
 *   "neutral/100": --var(--neutral-100, #F9FAFB)",
 *   "spacing/width/xxs": "--var(spacing-width-xxs, 320px)",
 *   "spacing/none": "--var(--spacing-none, 0px)",
 *   "spacing/2xs": "--var(--spacing-2xs, 4px)",
 *   "bg/primary": "--var(--bg-primary, #FFFFFF)",
 *   "bg/secondary": "--var(--bg-secondary, #F9FAFB)"
 * }
 * </example>
 *
 * Note: this includes mapping values in the format `{globalKey}.{tokenKey}` to
 * the value of the token.
 *
 * Note: the key is the path up until an object with the shape `{value: string,
 * type: string}`
 */
const flattenTokens = () => {
  const flatTokens: Record<string, string | Typography> = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flatten = (obj: any, path: string[] = []) => {
    for (const key in obj) {
      const tokenValue = obj[key]
      if (
        typeof tokenValue === 'object' &&
        'value' in tokenValue &&
        'type' in tokenValue
      ) {
        const token = path.concat(key).join('/')
        flatTokens[token] = resolveTokenValue(
          token,
          tokenValue.value,
          tokenValue.type
        )
      } else {
        flatten(tokenValue, [...path, key])
      }
    }
  }
  flatten({ ...tokens.global, ...tokens.spacing, ...tokens.colour })
  // add global.spacing as spacing/<size> tokens as well
  flatten({ spacing: tokens.global.spacing })

  return flatTokens
}

/**
 * Object with keys that match figma token names e.g. 'spacing/lg'
 */
export const namedTokens = flattenTokens()
// console.log("namedTokens['radius/2xl']", namedTokens['radius/2xl'])
// console.log("namedTokens['radius/full']", namedTokens['radius/full'])
// console.log("namedTokens['spacing/lg']", namedTokens['spacing/lg'])
// console.log("namedTokens['ring/brand']", namedTokens['ring/brand'])
// console.log("namedTokens['shadow/2xl']", namedTokens['shadow/2xl'])
// console.log(
//   "namedTokens['inter/display 2xl/medium']",
//   namedTokens['inter/display 2xl/medium']
// )
// console.log('namedTokens', namedTokens)

export const getToken = (name: keyof typeof namedTokens): string => {
  const token = namedTokens[name]

  if (typeof token === 'string') {
    return token
  } else if (token) {
    throw new Error(`Please use 'getFontToken(${name})'`)
  }

  throw new Error(`Token ${name} not found`)
}

export const getFontToken = (
  name: keyof typeof namedTokens,
  color?: keyof typeof namedTokens
): Typography & { color?: string } => {
  const fontToken = namedTokens[name]
  const colorToken = color ? namedTokens[color] : null

  if (color) {
    if (!colorToken) {
      throw new Error(`Token ${color} not found`)
    } else if (typeof colorToken !== 'string') {
      throw new Error(`Please use 'getToken(${color})'`)
    }
  }

  if (fontToken && typeof fontToken !== 'string') {
    return typeof colorToken === 'string'
      ? { ...fontToken, color: colorToken }
      : (fontToken as Typography)
  } else if (fontToken) {
    throw new Error(`Please use 'getToken(${name})`)
  }

  throw new Error(`Font token ${name} not found`)
}

function resolveTokenValue(
  token: string,
  value: TokenValue,
  type: TokenType
): string | Typography {
  let resolvedValue = value
  // if value is a string return it as a px value
  if (typeof resolvedValue === 'string') {
    // first lookup from global if needed
    resolvedValue = resolveTokenStringValue(resolvedValue)
    // this is more complex sometimes the string value needs to stay as the
    // string
    // e.g. spacing values need to be in px
    if (
      type === 'spacing' ||
      type === 'fontSize' ||
      type === 'lineHeights' ||
      type === 'paragraphSpacing' ||
      type === 'borderRadius'
    ) {
      resolvedValue = px(resolvedValue)
    }
    return wrapInVar(token, resolvedValue)
  }
  // if value is an array, resolve as a boxShadow
  if (type === 'boxShadow' && isBoxShadow(resolvedValue)) {
    return boxShadow(resolvedValue)
  }

  // Typography tokens see type Typography for shape
  if (
    type === 'typography' &&
    typeof resolvedValue === 'object' &&
    'fontFamily' in resolvedValue
  ) {
    return resolveTypography(resolvedValue)
  }

  // we never expect to get here so throw error if we do so we catch this early
  // in development process
  throw new Error(`unexpected token, type, value:  ${token}, ${type}, ${value}`)
}

/**
 * add css var with token as prefix of resolvedValue so that we document where
 * the value comes from but replace '/' in token with '-' as css vars can't have '/'
 */
function wrapInVar(token: string, value: string): string {
  return `var(--${token.replace(/\//g, '-')}, ${value})`
}

/**
 * a function to resolve a named typography token value
 *
 * input:
 * ```
 * {
 *   "fontFamily": "{fontFamilies.inter}",
 *   "fontWeight": "{fontWeights.inter-5}",
 *   "lineHeight": "{lineHeights.0}",
 *   "fontSize": "{fontSize.10}",
 *   "letterSpacing": "{letterSpacing.0}",
 *   "paragraphSpacing": "{paragraphSpacing.13}",
 *   "paragraphIndent": "{paragraphIndent.0}",
 *   "textCase": "{textCase.none}",
 *   "textDecoration": "{textDecoration.none}"
 * }
 * ```
 * output:
 * ```
 * {
 *   "fontFamily": "Inter",
 *   "fontWeight": "500",
 *   "lineHeight": "1.5",
 *   "fontSize": "18px",
 *   "letterSpacing": "0",
 *   "paragraphSpacing": "20px",
 *   "paragraphIndent": "0",
 *   "textCase": "none",
 *   "textDecoration": "none"
 * }
 */
function resolveTypography(typography: Typography): Typography {
  const resolvedTypography: Typography = {} as Typography
  for (const key in typography) {
    resolvedTypography[key] = resolveTokenStringValue(typography[key])
    if (
      key === 'fontSize' ||
      key === 'paragraphSpacing' ||
      key === 'lineHeight'
    ) {
      resolvedTypography[key] = px(parseInt(resolvedTypography[key]))
    }
  }
  return resolvedTypography
}

/**
 * a function to suffix 'px' to a number string
 */
function px(n: number | string): string {
  // if n is a string and contains a valid css unit return it as is
  // otherwise add px
  if (typeof n === 'string' && n.match(/px|em|rem|vw|vh|%/)) {
    return n
  }
  return `${n}px`
}

function hexToRgb(hex: string) {
  const bigint = parseInt(hex.replace('#', ''), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return `${r},${g},${b}`
}

function rgbaColor(hex: string): string {
  return `rgba(${hexToRgb(hex)},${opacity(hex)})`
}

/**
 * now check if the value is string and if it matches the look up from tokens.global
 * <example>`{spacing.4000}` -> `320`</example>
 * <example>`{spacing.0}` -> `0`</example>
 * <example>`{spacing.50}` -> `4`</example>
 * <example>`{neutral.25}` -> `#FFFFFF`</example>
 * <example>`{neutral.100}` -> `#F9FAFB`</example
 */
function resolveTokenStringValue(tokenValue: string): string {
  if (typeof tokenValue === 'string') {
    const token = tokenValue.match(/{(.*?)}/)?.[1]
    if (token) {
      // get the parts that were matched
      const [globalKey, tokenKey] = token.split('.')
      // now use tokens.global to resolve the value, checking for existence at
      // each level
      if (tokens.global[globalKey] && tokens.global[globalKey][tokenKey]) {
        return tokens.global[globalKey][tokenKey].value
      }
    }
  }
  return tokenValue
}

function opacity(hex: string): number {
  // Extract the alpha value from the hex color if it exists
  if (hex.length === 9) {
    const alphaHex = hex.slice(-2)
    const alphaDecimal = parseInt(alphaHex, 16)
    return alphaDecimal / 255
  }
  // Default to fully opaque if no alpha value is provided
  return 1
}

/**
 * function to turn dropShadow value into SX props for CSS
 * input:
 * {
 *  "color": "#1018280f",
 * "type": "dropShadow",
 * "x": "0",
 * "y": "1",
 * "blur": "2",
 * "spread": "0"
 * }
 * output:
 * '0px 1px 2px 0px rgba(16,24,40,0.06)'
 * Note: we are converting hex color to rgba to match the CSS syntax
 */
function dropShadow({
  x,
  y,
  blur,
  spread,
  color,
}: {
  x: string
  y: string
  blur: string
  spread: string
  color: string
}): string {
  return `${px(x)} ${px(y)} ${px(blur)} ${px(spread)} ${rgbaColor(color)}`
}

/**
 * function to turn boxShadow value into SX props for CSS
 * input:
 * [
 *   {
 *     "color": "#1018280f",
 *     "type": "dropShadow",
 *     "x": "0",
 *     "y": "1",
 *     "blur": "2",
 *     "spread": "0"
 *   },
 *   {
 *     "color": "#1018281a",
 *     "type": "dropShadow",
 *     "x": "0",
 *     "y": "1",
 *     "blur": "3",
 *     "spread": "0"
 *   }
 * ]
 * output:
 * '0px 1px 2px 0px rgba(16,24,40,0.06), 0px 1px 3px 0px rgba(16,24,40,0.1)'
 *
 * Note: we are converting hex color to rgba to match the CSS syntax
 *
 */
function boxShadow(shadows: Array<DropShadow> | DropShadow): string {
  if (isDropShadow(shadows)) {
    return dropShadow(shadows)
  }
  if (Array.isArray(shadows)) {
    return (shadows as Array<DropShadow>).map(dropShadow).join(', ')
  }
  console.warn('unexpected token type for boxShadow, ', shadows)
  throw new Error(`unexpected token type for boxShadow`)
}

/**
 * Type guard function for BoxShadow type
 */
function isBoxShadow(tokenValue: TokenValue): tokenValue is BoxShadow {
  return Boolean(
    tokenValue &&
      ((Array.isArray(tokenValue) &&
        tokenValue.length > 0 &&
        'type' in tokenValue[0]) ||
        (typeof tokenValue === 'object' && 'type' in tokenValue))
  )
}

/**
 * Typeguard for DropShadow type
 */
function isDropShadow(tokenValue: TokenValue): tokenValue is DropShadow {
  return Boolean(
    typeof tokenValue === 'object' &&
      'type' in tokenValue &&
      tokenValue.type === 'dropShadow'
  )
}
